/*============================================================================================= 
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/
@import "sass-lib";

@media all and (max-width: 1281px) {

    .scroll-wrap{height: auto;}
    .after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
    .before_clear:before{clear:both;display: block;content:" "}

    .wrapper {width: 940px;}


    /* header
    ----------------------------------------------------------------------------------------------*/
    header {
        padding-top: 0 !important; position: fixed !important; top: 0 !important;
        .wrapper {
            width: auto; margin-left: 40px;
        }
        .logo {
            margin-top: 15px;
            img {
                width: 120px;
            }
        }
        .rh_head {
            .main_nav {display: none;}
            .burger_mn {z-index: 2;}
        }
        &.float {
            .logo {margin-left: 0;}
        }
        .white_drop {z-index: 1;}
    }
    
    .side_mnu .wrap_side .nav_side ul .in_mobile {display: block;}

    /* homepage
    ----------------------------------------------------------------------------------------------*/
    .scrollify {height: auto;}
    .slideHome {
        .slider {
            .slide {
                .caption {
                    .wrapper {width: 780px;}
                }
                .images {
                    height: 700px; position: relative;
                    img {height: 100%; object-fit: cover; position: relative;}
                }
            }
            .slick-dots {bottom: 40px;}
        }
    }
    
    .home_business {
        background-size: 100%;padding: 75px 0 110px; position: relative; display: block; min-height: 700px;
        &:before {
            content:''; background-size: 100% !important; background: url('../images/content/repeat-service.jpg') repeat-y; position: absolute; bottom: 0; left: 0; right: 0; height: 60%;
            z-index: 0;
        }
        .listing {
            margin: 70px -1.70% 0;
            .item {
                padding: 40px 25px;
                h2 {height: auto; min-height: 55px;}
                a.learn {font-size: 13px;}
                p {line-height: 24px; max-height: 120px; overflow: hidden; position: relative;}
                .cover {
                    img {height: 100%; position: relative; display: block; object-fit: cover;}
                }
            }
        }
    }
    .home_about {
        min-height: 700px; background-size: 60%; overflow: hidden; position: relative;
        .img_about {position: absolute; width: 530px; top: 0; right: 0;}
        .text_about {
            margin-left: 80px; width: 400px;
            h2 {font-size: 25px; line-height: 30px;}
            h6 {font-size: 20px; line-height: 24px;}
            .btn {margin-top: 0;}
        }
    }
    .home_wcu {
        padding: 75px 0 50px;
        .top_sec {width: 520px; max-width: 100%;}
        .list_wcu {
            .col {
                margin-bottom: 55px;
                .img {width: 60px;}
                .text {
                    margin-left: 80px;
                    p {font-size: 16px; line-height: 24px;}
                }
            }
        }
        .link {
            .btn {margin-top: 0;}
        }
    }

    .scrollify.novh {height: auto; min-height: 520px;}


    .title {margin-bottom: 35px;}
    .top_sec {
        margin: 0 10%;
        h2 {line-height: 26px;}
        h6 {font-size: 20px;}
        h3 {font-size: 16px; line-height: 24px;}
    }
    /* middle
    ----------------------------------------------------------------------------------------------*/
    .inner_text {
        padding: 75px 80px 0;
        &:first-of-type {padding-bottom: 50px;}
        &:last-of-type {padding-bottom: 0px;}
        > h2 {font-size: 31px; margin-bottom: 40px;}
        .lf_txt {width: 260px;}
        .rh_txt {
            width: 480px;
            p {font-size: 16px;}
            h6 {font-size: 16px;}
            ul {
                li {
                    font-size: 16px; padding-left: 25px;
                    &:before {top: 10px;}
                }
            }
        }
        .col-2 {
            .col {float: none; display: block; margin-right: 0; width: auto;}
        }
        .client-list {
            a {width: 30%; margin-right: 5%;}
        }
        &.our-client {
            h4{font-size: 24px;}
        }
    }
    
    .next-read {
        padding: 70px 0;
        .wrapper {width: 940px; padding: 0 80px; @include boxSizing(border-box);}
        .left {
            width: 260px;
            h6 {font-size: 13px; margin-bottom: 15px;}
            h2 { font-size: 25px; }
        }
        .right {
            width: 480px;
            p {font-size: 16px; line-height: 24px;}
        }
    }
    .pg_title {
        &.bg-grey {
            position: relative; z-index: 1; overflow: hidden;
            + .inner_content {z-index: 2; position: relative; background: #fff;}
        }
        .map {
            @include transform(scale(0.85)); left: -17%; position: relative; overflow: hidden; top: -40px; margin-bottom: -70px;
            .sub-loc {
                left: 100px;
                .box {height: 45px; line-height: 45px;}
            }
            .detail-loc-info {
                bottom: 50px; right: 60px;
                .bg-white {padding: 30px 25px;}
                .close {width: 40px; height: 40px; background-size: 20px; right: -20px;}
            }
        }
        .txt_title_pg {
            width: 625px;
            h3 {font-size: 48px;}
            h2 {font-size: 25px; line-height: 30px;}
        }
        &.thank-banner {
            .txt_title_pg {
                h2 {font-size: 48px; line-height: 50px; margin-bottom: 30px;}
            }
        }
        &.scrollify {
            .img_bg {
                height: 100vh; min-height: 650px;
                img {height: 100%; width: 100%; object-fit: cover;}
            }

        }
    }

    .listing {
        &.about_sub {
            .item {
                .dec {
                    p {overflow: hidden; text-overflow: ellipsis; display: -webkit-inline-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; height: 47px;}
                }
            }
        }
    }

    .content_service {padding: 70px 0; min-height: 700px;}
    .service_list {
        .item {
            padding: 20px; margin-bottom: 10px;
            .ico {width: 60px;}
            .txt_item {
                margin-left: 100px;
                h6 {font-size: 16px;}
                p {font-size: 13px;}
            }
        }
    }
    .address-list{
        .list{
            flex: 1 0 50%;
            max-width: 50%;
        }
    }

    .slider_market {
        padding: 0; margin: 30px -5px 0;
        .slide {
            margin: 0 8px; padding-bottom: 70px;
            .dec {
                padding: 10px 20px;
                h6 {line-height: 22px;}
                p {font-size: 14px; height: 130px; overflow: hidden;}
            }
        }
        .slick-arrow {
            &.slick-next {right: -20px;}
            &.slick-prev {left: -20px;}
        }
    }
    .content_galery {
        .slider_galery {
            margin-top: 25px; width: 825px;
        }
        .slide {
            .img {width: 825px;}
        }
    }
    .content_client {padding: 100px 0 80px;}







    .wrap-popup {
        .box-popup {
            .slider-1 {
                .img {margin-bottom: 18px;}
                .text {
                    h6 {font-size: 18px;}
                }
            }
            .slider-2 {top: 400px;}
        }
    }
    /* footer
    ----------------------------------------------------------------------------------------------*/


}